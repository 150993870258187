import { Component, Input, OnInit, Optional } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IProjectForm } from '../project-form.component';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { get, lowerCase } from 'lodash';
import { ChipModule } from 'primeng/chip';
import { City, Country, ProjectDetailsResponse, Region } from '@shared/interfaces';
import { getProjectStatusItemClass } from '@shared/utils/common-utils';
import { InputTemplateComponent } from '@shared/form-inputs/input-template/input-template.component';
import { InputFieldComponent } from '@shared/form-inputs/input-field/input-field.component';
import { PROJECT_STATUS, TECHNOLOGIES } from '@constants/project';
import { LocationService } from '@Services/location/location.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { take } from 'rxjs/operators';
import { SiteService } from '@Services/site/site.service';
import { SiteDetailsResponse } from '@shared/interfaces/site.interface';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-general-form',
  standalone: true,
  imports: [
    InputTextModule,
    InputFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputTemplateComponent,
    DropdownModule,
    ChipModule,
    AutoCompleteModule,
  ],
  templateUrl: './general-form.component.html',
  styleUrl: './general-form.component.scss',
})
export class GeneralFormComponent implements OnInit {
  @Input() regions: Region[] = [];

  @Input()
  set data(data: ProjectDetailsResponse | null) {
    this.projectDetails = data;
    this.populateFormData(this.projectDetails);
  }

  public projectDetails: ProjectDetailsResponse | null = null;

  projectForm: FormGroup<IProjectForm> | null = null;

  generalFormControl: FormGroup | null = null;

  projectStatus = PROJECT_STATUS;

  technologies = TECHNOLOGIES;

  countries: Country[] = [];

  cities: City[] = [];

  sites: SiteDetailsResponse[] = [];

  loadingSiteCodes = false;

  selectedSiteCode: string | null;

  constructor(
    @Optional() private readonly parentControl: ControlContainer,
    private readonly toastMessageService: ToastMessageService,
    private readonly locationService: LocationService,
    private readonly siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.projectForm = this.parentControl?.control as FormGroup;
    this.generalFormControl = this.parentControl?.control?.get('generalForm') as FormGroup;
    if (this.projectDetails) {
      this.populateFormData(this.projectDetails);
    }
  }

  ngAfterContentChecked() {
    if (this.isContractedHoursDisabled) {
      this.generalFormControl?.get('contractedDischargeHours')?.disable();
    } else {
      this.generalFormControl?.get('contractedDischargeHours')?.enable();
    }
  }

  populateFormData(projectDetails: ProjectDetailsResponse | null) {
    if (!this.generalFormControl) {
      return;
    }
    if (!projectDetails) {
      this.generalFormControl?.reset();
      return;
    }

    this.populateGeneralForm(this.generalFormControl, projectDetails);
  }

  populateGeneralForm(generalForm: FormGroup, projectDetails: ProjectDetailsResponse) {
    generalForm.get('portfolio')?.setValue(projectDetails.portfolio);
    generalForm.get('name')?.setValue(projectDetails.name);
    generalForm.get('address')?.setValue(projectDetails.address);
    generalForm.get('coordinates')?.setValue(projectDetails.coordinates);
    generalForm.get('capacityDC')?.setValue(projectDetails.capacity_dc);
    generalForm.get('capacityAC')?.setValue(projectDetails.capacity_ac);
    generalForm.get('capacityBESS')?.setValue(projectDetails.capacity_bess);
    generalForm.get('capacityPOI')?.setValue(projectDetails.capacity_poi);
    generalForm.get('siteId')?.setValue(projectDetails.site?.id ?? null);
    generalForm
      .get('contractedDischargeHours')
      ?.setValue(projectDetails?.contracted_discharge_hours ?? null);
    this.selectedSiteCode = projectDetails.site?.code ?? null;

    generalForm
      .get('status')
      ?.setValue(PROJECT_STATUS.find((status) => status.name === projectDetails.status) || null);

    generalForm
      .get('technology')
      ?.setValue(
        TECHNOLOGIES.find((technology) => technology.name === projectDetails.technology) || null,
      );

    const selectedRegion =
      this.regions.find((region) => region.name === projectDetails.region?.name) || null;

    if (selectedRegion) {
      generalForm.get('region')?.setValue(selectedRegion);
      generalForm?.get('country')?.enable();
      this.setCountriesByRegion(selectedRegion, projectDetails.country?.name); // Pass the country name to set it later
    } else {
      generalForm.get('region')?.setValue(this.regions);
    }
  }

  setCountriesByRegion(region: Region, selectedCountryName?: string) {
    this.locationService
      .getAllCountriesByRegion(region?.id)
      .pipe(take(1))
      .subscribe({
        next: (countries) => {
          this.countries = countries;

          if (selectedCountryName) {
            const selectedCountry = this.countries.find(
              (country) => country.name === selectedCountryName,
            );
            if (selectedCountry) {
              this.generalFormControl?.get('country')?.setValue(selectedCountry);
              this.generalFormControl?.get('city')?.enable();
              this.setCitiesByCountry(selectedCountry, this.projectDetails?.city?.name); // Pass the city name to set it later
            }
          } else {
            this.generalFormControl?.get('country')?.enable();
            this.generalFormControl?.get('city')?.disable();
            this.generalFormControl?.get('country')?.setValue(null);
            this.generalFormControl?.get('city')?.setValue(null);
          }
        },
        error: (httpError: HttpErrorResponse) => {
          this.toastMessageService.showValidationErrors(
            httpError.error,
            httpError?.error?.error_message ?? 'Unable to get countries',
          );
        },
      });
  }

  onRegionChange(region: Region | null) {
    if (region) {
      this.setCountriesByRegion(region);
    } else {
      this.countries = [];
      this.generalFormControl?.get('country')?.setValue(null);
      this.generalFormControl?.get('country')?.disable();
    }
  }

  setCitiesByCountry(country: Country, selectedCityName?: string) {
    this.locationService
      .getAllCitiesByCountry(country?.id)
      .pipe(take(1))
      .subscribe({
        next: (cities) => {
          this.cities = cities;
          this.generalFormControl?.get('city')?.enable();
          this.generalFormControl?.get('city')?.setValue(null);

          if (selectedCityName) {
            const selectedCity = this.cities.find((city) => city.name === selectedCityName);
            if (selectedCity) {
              this.generalFormControl?.get('city')?.setValue(selectedCity);
            }
          }
        },
        error: (httpError: HttpErrorResponse) => {
          this.toastMessageService.showValidationErrors(
            httpError.error,
            httpError?.error?.error_message ?? 'Unable to get cities',
          );
        },
      });
  }

  onCountryChange(country: Country | null) {
    if (country) {
      this.setCitiesByCountry(country);
    } else {
      this.cities = [];
      this.generalFormControl?.get('city')?.setValue(null);
      this.generalFormControl?.get('city')?.disable();
    }
  }

  loadSiteItemsLazy(event: { query: string }) {
    this.onSiteCodeChange(event.query);
  }

  onClear() {
    this.onSiteCodeChange(null);
  }

  onUnselect() {
    this.generalFormControl?.get('siteId')?.setValue(null);
  }

  onSiteSelect(event: AutoCompleteSelectEvent) {
    this.generalFormControl?.get('siteId')?.setValue(event.value.id);
    this.selectedSiteCode = event.value.code;
  }

  onSiteCodeChange(searchText: string | null) {
    // Reset the siteId if the user is typing and keep its value in selectedSiteCode to show it in the input until it is focused
    this.generalFormControl?.get('siteId')?.setValue(null);
    if (!searchText || (searchText && get(searchText, 'length', 0) < 2)) {
      this.sites = [];
      return;
    }
    this.loadingSiteCodes = true;

    this.siteService
      .getSiteCodes(searchText)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.sites = response ?? [];
          this.loadingSiteCodes = false;
        },
        error: (httpError: HttpErrorResponse) => {
          this.loadingSiteCodes = false;
          this.toastMessageService.showValidationErrors(
            httpError.error,
            httpError?.error?.error_message,
          );
        },
      });
  }

  get countryName(): string {
    return get(this.generalFormControl?.get('country')?.value, 'name') || '';
  }

  get countryCode(): string {
    return lowerCase(get(this.generalFormControl?.get('country')?.value, 'code') || '');
  }

  get city(): string {
    return get(this.generalFormControl?.get('city')?.value, 'name') || '';
  }

  get technology(): string {
    return get(this.generalFormControl?.get('technology')?.value, 'name') || '';
  }

  get region(): string {
    return get(this.generalFormControl?.get('region')?.value, 'name') || '';
  }

  get status(): string {
    return get(this.generalFormControl?.get('status')?.value, 'name') || '';
  }

  get coordinationFormatValidation(): boolean {
    return !!get(this.generalFormControl?.get('coordinates'), 'errors.invalidLatLng');
  }

  get isContractedHoursDisabled(): boolean {
    const technology = get(this.generalFormControl?.get('technology')?.value, 'name');
    return (technology !== 'BESS' && technology !== 'PV+BESS') || false;
  }

  getSiteName(site: unknown): string {
    if (!site) {
      return '';
    }
    return ` ${get(site, 'code')} - ${get(site, 'name')}`;
  }

  getProjectStatusItemClass = getProjectStatusItemClass;
}
